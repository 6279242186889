<template>
  <div>
    <h2 class="mb-4">Experiment 3: Results - Part B</h2>

    <p class="mb-2">
      a) What was the slope of your calibration curve created for the data from Part A of the
      experiment?
    </p>

    <calculation-input
      v-model="inputs.slope"
      class="mb-5"
      prepend-text="$\text{Slope}:$"
      :disabled="!allowEditing"
    />

    <p class="mb-3">
      b) In the table below, enter your experimental data for the three solutions you prepared and
      analyzed.
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table dense>
        <thead>
          <tr>
            <td></td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Test }$" />
              <stemble-latex content="$\text{Tube }$" />
              <stemble-latex content="$1$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Test }$" />
              <stemble-latex content="$\text{Tube }$" />
              <stemble-latex content="$2$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Test }$" />
              <stemble-latex content="$\text{Tube }$" />
              <stemble-latex content="$3$" />
            </td>
          </tr>

          <tr v-for="result in labResults" :key="result.value">
            <td>
              <stemble-latex :content="result.property" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs[result.value1]"
                @input="inputs[result.value1] = $event !== '' ? Number($event) : null"
              />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs[result.value2]"
                @input="inputs[result.value2] = $event !== '' ? Number($event) : null"
              />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs[result.value3]"
                @input="inputs[result.value3] = $event !== '' ? Number($event) : null"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </p>

    <p class="mb-2">
      c) What is the average value of
      <stemble-latex content="$\text{K}_\text{eq}$" />
      for the reaction based on your data?
    </p>

    <calculation-input
      v-model="inputs.Keq"
      class="mb-5"
      prepend-text="$\text{K}_\text{eq}:$"
      :disabled="!allowEditing"
    />

    <p class="mb-2">
      d) Please upload the data sheet from Part B of the experiment as a pdf file using the upload
      field below.
    </p>

    <p class="mb-0">
      <v-file-input v-model="attachments" multiple accept="application/pdf" />
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'McMaster1A03Lab3ResultsPartB',
  components: {
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        slope: null,
        volKSCN1: null,
        volFeNO31: null,
        volH2O1: null,
        Vtot1: null,
        concSCNInit1: null,
        concFeInit1: null,
        abs1: null,
        concFeSCNEq1: null,
        concSCNEq1: null,
        concFeEq1: null,
        Keq1: null,

        volKSCN2: null,
        volFeNO32: null,
        volH2O2: null,
        Vtot2: null,
        concSCNInit2: null,
        concFeInit2: null,
        abs2: null,
        concFeSCNEq2: null,
        concSCNEq2: null,
        concFeEq2: null,
        Keq2: null,

        volKSCN3: null,
        volFeNO33: null,
        volH2O3: null,
        Vtot3: null,
        concSCNInit3: null,
        concFeInit3: null,
        abs3: null,
        concFeSCNEq3: null,
        concSCNEq3: null,
        concFeEq3: null,
        Keq3: null,

        Keq: null,
        discussion: null,
      },
      attachments: [],
      labResults: [
        {
          property: '$\\text{V}_\\text{KSCN}\\text{(mL)}$',
          value1: 'volKSCN1',
          value2: 'volKSCN2',
          value3: 'volKSCN3',
        },
        {
          property: '$\\text{V}_\\ce{Fe(NO3)3}\\text{(mL)}$',
          value1: 'volFeNO31',
          value2: 'volFeNO32',
          value3: 'volFeNO33',
        },
        {
          property: '$\\text{V}_\\ce{H2O}\\text{(mL)}$',
          value1: 'volH2O1',
          value2: 'volH2O2',
          value3: 'volH2O3',
        },
        {
          property: '$\\text{V}_\\text{tot}\\text{(mL)}$',
          value1: 'Vtot1',
          value2: 'Vtot2',
          value3: 'Vtot3',
        },
        {
          property: '$\\ce{[SCN-]_i\\text(M)}$',
          value1: 'concSCNInit1',
          value2: 'concSCNInit2',
          value3: 'concSCNInit3',
        },
        {
          property: '$\\ce{[Fe^3+]_i\\text(M)}$',
          value1: 'concFeInit1',
          value2: 'concFeInit2',
          value3: 'concFeInit3',
        },
        {property: '$\\text{Abs}$', value1: 'abs1', value2: 'abs2', value3: 'abs3'},
        {
          property: '$\\ce{[FeSCN^2+]_\\text{eq}\\text{(M)}}$',
          value1: 'concFeSCNEq1',
          value2: 'concFeSCNEq2',
          value3: 'concFeSCNEq3',
        },
        {
          property: '$\\ce{[SCN-]_\\text{eq}\\text{(M)}}$',
          value1: 'concSCNEq1',
          value2: 'concSCNEq2',
          value3: 'concSCNEq3',
        },
        {
          property: '$\\ce{[Fe^3+]_\\text{eq}\\text{(M)}}$',
          value1: 'concFeEq1',
          value2: 'concFeEq2',
          value3: 'concFeEq3',
        },
        {property: '$\\text{K}_\\text{eq}$', value1: 'Keq1', value2: 'Keq2', value3: 'Keq3'},
      ],
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
