import { render, staticRenderFns } from "./McMaster1A03Lab3ResultsPartB.vue?vue&type=template&id=3df4ef9e&"
import script from "./McMaster1A03Lab3ResultsPartB.vue?vue&type=script&lang=js&"
export * from "./McMaster1A03Lab3ResultsPartB.vue?vue&type=script&lang=js&"
import style0 from "./McMaster1A03Lab3ResultsPartB.vue?vue&type=style&index=0&id=3df4ef9e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VFileInput,VSimpleTable})
